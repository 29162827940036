import React, { useState, createContext, useContext, useEffect } from "react";
import { datadogSetRoom } from "../../datadog";
import { useAnalyticsContext } from "../AnalyticsProvider";

export const WaitingRoomContext = createContext({});

export function WaitingRoomProvider({ children }) {
  const [room, setRoom] = useState({
    roomAlias: "",
    therapistDisplayName: "",
    therapistPhotoUrl: "",
    profileName: "",
    profileFirstName: "",
    countryCode: "",
    languageCode: "",
    clientTimer: false,
    setupReady: false,
    lastSeenVersion: "",
    profileId: "",
  });
  const { setUserDetails } = useAnalyticsContext();

  useEffect(() => {
    datadogSetRoom(room.roomAlias);
    setUserDetails({
      profileId: room.profileId,
      countryCode: room.countryCode,
    });
  }, [room, setUserDetails]);

  const getRoomUrl = () =>
    `${window.location.protocol}//${window.location.hostname}/${room.roomAlias}`;

  return (
    <WaitingRoomContext.Provider
      value={{
        room,
        setRoom,
        getRoomUrl,
      }}
    >
      {children}
    </WaitingRoomContext.Provider>
  );
}

export function useWaitingRoomContext() {
  const context = useContext(WaitingRoomContext);
  if (!context) {
    throw new Error(
      "useWaitingRoomContext must be used with a WaitingRoomProvider",
    );
  }

  return context;
}
