import {
  useRef,
  useContext,
  createContext,
  useCallback,
  useMemo,
  useState,
  useEffect,
} from "react";
import { sendAnalytics } from "../../api/appgateway";
import { datadogEvent, datadogSetPage } from "../../datadog";
import { removeUndefined } from "../../utils";

import { v4 as uuidv4 } from "uuid";

const sessionId = uuidv4();

export const AnalyticsContext = createContext(null);

export function AnalyticsProvider({ children }) {
  const pendingEvents = useRef([]);
  const isSending = useRef(false);
  const page = useRef("");
  const [profileId, setProfileId] = useState("");
  const [countryCode, setCountryCode] = useState("");

  const setUserDetails = useCallback(({ profileId, countryCode }) => {
    setProfileId(`${profileId}`);
    setCountryCode(countryCode);
  }, []);

  const analyticsEvent = ({
    eventName,
    actionType,
    actionName,
    context = {},
    skipDatadog = false,
  }) => {
    const eventDetail = {
      action_name: actionName,
      action_type: actionType,
      page_location: window.location.href,
      page_referrer: document.referrer,
      page_title: document.title,
      page_type: page.current,
      ...context,
    };

    removeUndefined(eventDetail);

    pendingEvents.current.push({
      name: eventName,
      timestamp: new Date().getTime(),
      detail: eventDetail,
    });
    if (!skipDatadog) {
      datadogEvent({
        category: eventName,
        feature: actionName,
        event: actionType,
      });
    }
  };

  const eventSource = useMemo(
    () => ({
      id: "SessionsWeb",
      property: "Member",
      domain: "sessions",
      user_agent: window.navigator.userAgent,
      profile_id: profileId,
      country_code: countryCode,
      language: window.navigator.language,
      platform: os(),
      mobile: isMobile(),
      client_type: "web",
    }),
    [profileId, countryCode],
  );

  const setPage = p => {
    if (page.current !== p) {
      page.current = p;
      analyticsEvent({ eventName: "page_view", skipDatadog: true });
      datadogSetPage(p);
    }
  };

  const sendEvents = useCallback(async () => {
    const metrics = [...pendingEvents.current];
    if (!metrics.length || isSending.current) {
      return;
    }
    isSending.current = true;
    pendingEvents.current = [];
    const res = await sendAnalytics({
      session_id: sessionId,
      source: eventSource,
      metrics: metrics,
    });
    if (!res.success) {
      // If we encounter an error sending metrics, add them back in to our state
      pendingEvents.current = [...pendingEvents.current, ...metrics];
    }
    isSending.current = false;
  }, [eventSource]);

  // Send every ten seconds or on unload
  useEffect(() => {
    const intervalID = setInterval(sendEvents, 10 * 1000);
    document.addEventListener("visibilitychange", sendEvents);
    window.addEventListener("pagehide", sendEvents);
    window.addEventListener("beforeunload", sendEvents);

    return () => {
      clearInterval(intervalID);
      document.removeEventListener("visibilitychange", sendEvents);
      window.document.removeEventListener("pagehide", sendEvents);
      window.document.removeEventListener("beforeunload", sendEvents);
    };
  }, [sendEvents]);

  return (
    <AnalyticsContext.Provider
      value={{
        analyticsEvent,
        setUserDetails,
        setPage,
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  );
}

export const useAnalyticsContext = () => {
  const context = useContext(AnalyticsContext);
  if (!context) {
    throw new Error(
      "useAnalyticsContext must be used within a AnalyticsContext",
    );
  }

  return context;
};

export const useSetPage = pageName => {
  const { setPage } = useAnalyticsContext();
  setPage(pageName);
};

const isMobile = () => {
  return window.navigator.userAgent.indexOf("Mobile") !== -1 ? "1" : "0";
};

const os = () => {
  const ua = window.navigator.userAgent;

  if (ua.indexOf("Windows") !== -1) {
    return "Windows";
  } else if (ua.indexOf("Mac OS") !== -1) {
    return "MacOS";
  } else if (ua.indexOf("Linux") !== -1) {
    return "Linux";
  }
  return "";
};

export default AnalyticsProvider;
