import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { H2 } from "@sussex/react-kit/elements";
import useCopy from "../../hooks/useCopy";
import { SlideoutPanel } from "../SlideoutLayout";

const Iframe = styled.iframe`
  border: none;
  width: 100%;
  height: 100%;
`;

const TERMS_DOC = "terms";
const PRIVACY_POLICY_DOC = "policy";

export default function TermsOfService() {
  const [active, setActive] = useState(false);
  const details = useRef(null);
  const language = navigator.language.split("-")[0];
  const [termsTitle, privacyTitle] = useCopy([
    "checkinscreen.termLink",
    "checkinscreen.privacyLink",
  ]);

  const close = () => {
    setActive(false);
  };

  useEffect(() => {
    const openTos = () => {
      setActive(true);
      details.current = {
        title: termsTitle,
        url: `${
          process.env.REACT_APP_ACTIVE_CONSENT_URL
        }/${TERMS_DOC}/${language}/latest?t=${Date.now()}`,
      };
    };
    const openPrivacy = () => {
      setActive(true);
      details.current = {
        title: privacyTitle,
        url: `${
          process.env.REACT_APP_ACTIVE_CONSENT_URL
        }/${PRIVACY_POLICY_DOC}/${language}/latest?t=${Date.now()}`,
      };
    };

    // This event is triggered from the "Show details"
    // button of the cookiebot banner, and from the
    // client check in component.
    document.addEventListener("tos", openTos);
    document.addEventListener("privacy-policy", openPrivacy);
    return () => {
      document.removeEventListener("tos", openTos);
      document.removeEventListener("privacy-policy", openPrivacy);
    };
  }, [privacyTitle, termsTitle, language]);

  return (
    <SlideoutPanel
      active={active}
      onClose={close}
      header={() => <H2>{details.current?.title}</H2>}
      overlap={true}
      position="right"
      childPanelCleanup={() => {
        details.current = null;
      }}
    >
      <Iframe src={details.current?.url} />
    </SlideoutPanel>
  );
}
