import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import useVideoContext from "../../hooks/useVideoContext";
import useMainSpeaker from "../../hooks/useMainSpeaker";
import useIsTrackEnabled from "../../hooks/useIsTrackEnabled";
import usePublications from "../../hooks/usePublications";
import useTrack from "../../hooks/useTrack";
import useInactive from "../../hooks/useInactive";
import useParticipantInfo from "../../hooks/useParticipantInfo";
import ParticipantControls from "../ParticipantControls";
import Participant from "../Participant/";
import ParticipantInfo from "../ParticipantInfo";
import { useSessionMenuContext } from "../../providers/SessionMenuProvider";

const ParticipantWrapper = styled.div`
  width: auto;
  height: 100%;
  position: relative;
  flex: 1 1 auto;
  margin: 0 auto auto 0;
  overflow: hidden;
  background: ${({ theme }) => theme.colors.dark};

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: auto;
    margin: auto;
  }

  & > div {
    width: 100%;
    height: 100%;
  }

  & video {
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute;
    height: 100%;
  }
`;

// Video positioning rules:
//    window is portrait, video is portrait: contain
//    window is portrait, video is landscape: set container to 16/9 aspect ratio and cover
//    window is landscape, video is portrait: contain
//    window is landscape, video is landscape: cover
const VideoWrapper = styled(ParticipantWrapper)`
  border-radius: 16px;
  height: calc(100% - 90px);
  flex-grow: 0;
  margin: 16px;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    height: calc(100% - 115px);
  }

  @media (orientation: portrait) {
    & video {
      object-fit: contain;
    }

    ${({ videoOrientation }) =>
      videoOrientation === "landscape" &&
      css`
        position: relative;

        & > div {
          position: absolute;
          top: 0%;
          right: 0;
          bottom: 0%;
          left: 0;
        }

        & video {
          object-fit: cover;
        }
      `};
  }

  @media (orientation: landscape) {
    height: calc(100% - 90px);
    & video {
      object-fit: ${({ videoOrientation }) =>
        videoOrientation === "portrait" ? "contain" : "cover"};
    }
  }
`;

const ScreenShareWrapper = styled(ParticipantWrapper)`
  background: ${({ theme }) => theme.colors.default};

  & video {
    width: 95%;
    object-fit: contain;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    & video {
      /* Prevent video from covering the secondary participant list */
      ${({ participantListVisible }) =>
        participantListVisible &&
        css`
          width: calc(100% - 300px);
          margin-left: 25px;
        `}
    }
  }
`;

const InfoWrap = styled.div`
  position: absolute;
  bottom: 16px;
  left: 16px;
  ${({ hideParticipantInfo }) =>
    hideParticipantInfo
      ? css`
          display: none;
        `
      : css`
          display: flex;
        `};
`;

export default function MainParticipant({ isTherapist, hideParticipantInfo }) {
  const [video, setVideo] = useState(null);
  const [videoOrientation, setVideoOrientation] = useState(null);
  const mainParticipant = useMainSpeaker();
  const publications = usePublications(mainParticipant);
  const audioPublication = publications.find(p => p.kind === "audio");
  const audioTrack = useTrack(audioPublication);
  const isInactive = useInactive(3000);
  const isAudioEnabled = useIsTrackEnabled(audioTrack);
  const {
    room: { localParticipant },
    selectedParticipant,
    setSelectedParticipant,
    screenShareParticipant,
    sendMessage,
  } = useVideoContext();
  const { displayName } = useParticipantInfo(mainParticipant.identity);
  const screenTrack = Array.from(mainParticipant.tracks.values()).find(track =>
    track.trackName.includes("screen"),
  );
  const isScreenshare = typeof screenTrack !== "undefined";
  const { participantListVisible } = useSessionMenuContext();
  const Wrapper = isScreenshare ? ScreenShareWrapper : VideoWrapper;

  useEffect(() => {
    if (!video) return;
    const measureVideo = () => {
      setVideoOrientation(
        video.videoHeight >= video.videoWidth ? "portrait" : "landscape",
      );
    };
    video.addEventListener("loadedmetadata", measureVideo);
    return () => {
      video.removeEventListener("loadedmetadata", measureVideo);
    };
  }, [video]);

  const toggleMuteParticipant = () => {
    const message = isAudioEnabled ? "mute" : "unmute";

    sendMessage({
      type: message,
      to: mainParticipant.identity,
    });
  };

  const removeParticipant = () => {
    sendMessage({
      type: "kick",
      to: mainParticipant.identity,
    });
  };
  const pinParticipant = () => {
    setSelectedParticipant(mainParticipant);
  };

  const showAdminControls = isTherapist && mainParticipant !== localParticipant;
  return (
    <Wrapper
      videoOrientation={videoOrientation}
      participantListVisible={participantListVisible}
    >
      <div>
        <Participant
          participant={mainParticipant}
          isMain={true}
          setVideo={setVideo}
          enableScreenShare={true}
          showAdminControls={showAdminControls}
          isScreenshare={isScreenshare}
          full
        >
          <InfoWrap
            hideParticipantInfo={hideParticipantInfo}
            videoOrientation={videoOrientation}
          >
            <ParticipantInfo participant={mainParticipant} />
            <ParticipantControls
              isMain={true}
              isVisible={!isInactive}
              showAdminControls={showAdminControls}
              isSelected={selectedParticipant === mainParticipant}
              setSelected={pinParticipant}
              isAudioEnabled={isAudioEnabled}
              toggleMuteParticipant={toggleMuteParticipant}
              showPinControls={
                !screenShareParticipant ||
                screenShareParticipant === localParticipant
              }
              removeParticipant={removeParticipant}
              identity={mainParticipant.identity}
              displayName={displayName}
            />
          </InfoWrap>
        </Participant>
      </div>
    </Wrapper>
  );
}
