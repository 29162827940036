import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import MainParticipant from "../MainParticipant";
import GridView from "../GridView";
import SecondaryParticipants from "../SecondaryParticipants";
import SessionControls from "../SessionControls";
import useWindowSize from "../../hooks/useWindowSize";
import useVideoContext from "../../hooks/useVideoContext";
import { NotificationProvider } from "../Notifications";
import InSessionNotifications from "./InSessionNotifications";
import ConnectivityNotifications from "./ConnectivityNotifications";
import { useSessionMenuContext } from "../../providers/SessionMenuProvider";
import DisconnectPrompt from "./DisconnectPrompt";
import ElapsedTime from "../SessionControls/ElapsedTime";
import Reconnecting from "./Reconnecting";
import SettingsMenu from "../SettingsMenu";
import ExitConfirmation from "./ExitConfirmation";
import { useWaitingRoomContext } from "../../providers/WaitingRoomProvider";
import { SessionChat, useChatContext } from "../Chat";
import InSessionWaitingRoom, {
  InSessionWaitingRoomProvider,
} from "../InSessionWaitingRoom";
import VideoAndMicSettings from "../VideoAndMicSettings";
import ParticipantAudioTracks from "../ParticipantAudioTracks";
import { appInstanceId } from "../../App";
import { datadogEvent } from "../../datadog";

const PageWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: ${({ height }) => height + "px"};
  overflow: hidden;
  display: flex;
  flex-direction: row;
  background-color: ${({ theme }) => theme.colors.default};
  z-index: 1;
`;

const VideoWrap = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  transition: margin 0.5s ease;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    ${({ menuLeft = false, menuRight = false }) => {
      if (menuLeft) {
        return css`
          margin-left: 416px;
        `;
      }
      if (menuRight) {
        return css`
          margin-right: 416px;
        `;
      }
    }}
  }
  @media (min-width: 1532px) {
    ${({ menuLeft = false, menuRight = false }) => {
      if (menuLeft) {
        return css`
          margin-left: 416px;
        `;
      }
      if (menuRight) {
        return css`
          margin-right: 416px;
        `;
      }
    }}
  }
`;

const BackgroundCover = styled.div`
  background-color: ${({ theme }) => theme.colors.default};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const ControlsWrap = styled.div`
  position: absolute;
  top: ${({ height }) => height}px;
  left: 0;
  right: 0;
  width: 100%;
  margin: 0 auto;
  z-index: 10;
  transform: translateY(-100%);
`;

const TopButtonWrap = styled.div`
  position: absolute;
  display: flex;
  top: 32px;
  left: 32px;

  & > * {
    margin-right: 10px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    top: 36px;
    left: 36px;
  }
`;

const SettingsMenuWrap = styled.div`
  position: absolute;
  bottom: 80px;
  right: 10px;
  box-shadow: 0px 4px 15px rgba(44, 45, 48, 0.3);
  border-radius: 5px;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    bottom: 100px;
    right: 20px;
  }
`;

const VideoMenuWrap = styled.div`
  position absolute;
  top: 0;
  bottom: 0;
  width: calc(100% - 32px);
  height: calc(100% - 90px);
  z-index: 1000000;
  margin: 16px;
  overflow: hidden;
  display: ${({ open }) => (open ? "block" : "none")};

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    display: block;
    margin-right: ${({ rightSide = false }) => (rightSide ? "16px" : "0px")};
    width: 400px;
    max-width: 400px;
    height: calc(100% - 90px);
    @media (orientation: portrait) {
      height: calc(100% - 115px);
    }
    transition: transform .5s ease;
    ${({ rightSide = false }) =>
      rightSide
        ? css`
            right: 0;
            transform: translateX(calc(100% + 32px));
          `
        : css`
            left: 0;
            transform: translateX(calc(-100% - 32px));
          `}
    ${({ open }) =>
      open &&
      css`
        transform: translateX(0);
      `}

  }

  @media (min-width: 1532px) {
    height: calc(100% - 90px);
    @media (orientation: portrait) {
      height: calc(100% - 115px);
    }
    width: 400px;
    max-width: 400px;
  }

  border-radius: 16px;
  background: white;
  flex: 1;
`;

const Providers = ({ children }) => {
  return (
    <NotificationProvider>
      <InSessionWaitingRoomProvider>{children}</InSessionWaitingRoomProvider>
    </NotificationProvider>
  );
};

const View = ({ isTherapist }) => {
  const { height } = useWindowSize();
  const {
    participants,
    screenShareParticipant,
    selectedParticipant,
    room: { sid: roomId, localParticipant },
  } = useVideoContext();
  const {
    participantListVisible,
    menuOpen: settingsOpen,
    setMenuOpen: setSettingsOpen,
    changingAudioVideoSettings,
    setChangingAudioVideoSettings,
  } = useSessionMenuContext();
  const { room: waitingRoom } = useWaitingRoomContext();
  const [exitConfirmationOpen, setExitConfirmationOpen] = useState(false);
  const [waitingRoomOpen, _setWaitingRoomOpen] = useState(false);
  const [inSessionChatOpen, _setInsessionChatOpen] = useState(false);
  const { isActiveChannel, setActiveChannel } = useChatContext();

  const setWaitingRoomOpen = val => {
    _setWaitingRoomOpen(val);
    if (val) {
      _setInsessionChatOpen(false);
      if (chatOpen) {
        setActiveChannel("");
      }
    }
  };

  const chatOpen = isActiveChannel(roomId);

  useEffect(() => {
    if (chatOpen) {
      _setInsessionChatOpen(true);
      _setWaitingRoomOpen(false);
    } else {
      _setInsessionChatOpen(false);
    }
  }, [chatOpen]);

  const viewType =
    !selectedParticipant &&
    (!screenShareParticipant || screenShareParticipant === localParticipant) &&
    participants.length === 2
      ? "grid"
      : "full";

  const closeMediaSettings = () => {
    setChangingAudioVideoSettings(false);
  };

  useEffect(() => {
    datadogEvent({
      category: "session",
      event: "started",
      component: "VideoSession",
    });

    const onFullscreenChange = () => {
      datadogEvent({
        category: "session",
        feature: "fullscreen",
        event: document.fullscreenElement ? "enabled" : "disabled",
        component: "VideoSession",
      });
    };

    document.addEventListener("fullscreenchange", onFullscreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", onFullscreenChange);
    };
  }, []);

  return (
    <>
      <ParticipantAudioTracks />

      {isTherapist && !exitConfirmationOpen && <DisconnectPrompt />}
      {exitConfirmationOpen && (
        <ExitConfirmation onClose={() => setExitConfirmationOpen(false)} />
      )}
      <InSessionNotifications isTherapist={isTherapist} />
      <ConnectivityNotifications />
      <BackgroundCover />
      <PageWrap height={height}>
        <VideoMenuWrap open={waitingRoomOpen}>
          {waitingRoomOpen && isTherapist ? (
            <InSessionWaitingRoom
              waitingRoomOpen={waitingRoomOpen}
              setWaitingRoomOpen={setWaitingRoomOpen}
            />
          ) : null}
        </VideoMenuWrap>

        <VideoWrap menuLeft={waitingRoomOpen} menuRight={inSessionChatOpen}>
          {viewType === "grid" ? (
            <GridView isTherapist={isTherapist} />
          ) : (
            <MainParticipant isTherapist={isTherapist} />
          )}
          <SecondaryParticipants
            isTherapist={isTherapist}
            visible={participantListVisible}
          />
          <TopButtonWrap>
            {(isTherapist || waitingRoom.clientTimer) && (
              <ElapsedTime isTherapist={isTherapist} />
            )}
          </TopButtonWrap>
          {settingsOpen && (
            <SettingsMenuWrap>
              <SettingsMenu isTherapist={isTherapist} />
            </SettingsMenuWrap>
          )}
          <VideoAndMicSettings
            active={changingAudioVideoSettings}
            onClose={closeMediaSettings}
            overlap={false}
            testsEnabled={false}
          />

          <Reconnecting />
        </VideoWrap>
        <VideoMenuWrap open={inSessionChatOpen} rightSide>
          <SessionChat channel={appInstanceId} />
        </VideoMenuWrap>
      </PageWrap>
      <ControlsWrap height={height}>
        <SessionControls
          isTherapist={isTherapist}
          setExitConfirmationOpen={setExitConfirmationOpen}
          waitingRoomOpen={waitingRoomOpen}
          setWaitingRoomOpen={setWaitingRoomOpen}
          settingsOpen={settingsOpen}
          setSettingsOpen={setSettingsOpen}
          hidden={false}
        />
      </ControlsWrap>
    </>
  );
};

export default function VideoSession({ isTherapist }) {
  return (
    <Providers>
      <View isTherapist={isTherapist} />
    </Providers>
  );
}
