import React, { useEffect, useRef } from "react";
import styled, { css, useTheme } from "styled-components";
import useMediaQuery from "@mui/material/useMediaQuery";
import { CloseButton, BackButton } from "@sussex/react-kit/elements";

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  background: white;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    box-shadow: 0px 25px 50px 0px rgba(31, 41, 55, 0.25);
    border-radius: 16px;
  }
`;

const PanelHeader = styled.div`
  display: flex;
  background: white;
  justify-content: center;
  padding: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  position: relative;
`;

const HeaderContent = styled.div`
  width: 100%;
  flex: 1 1 auto;
  display: flex;
  align-items: center;

  ${({ center }) =>
    center &&
    css`
      justify-content: center;
    `}
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex: 0 0 auto;
  align-items: center;
`;

const PanelBody = styled.div`
  position: relative;
  flex: 1;
  overflow-y: auto;
`;

export default function Panel({ header, onClose, onBack, children }) {
  const headerRef = useRef(null);
  const theme = useTheme();
  const xs = !useMediaQuery(`(min-width:${theme.breakpoints.tablet})`);

  // Prevent scroll when open in full screen layout
  useEffect(() => {
    if (!xs) return;
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "";
    };
  }, [xs]);

  // Prevent scrolling body when touchmove fired on panel header.
  // Addresses issue where iOS does not respect `overflow: hidden`
  // when virtual keyboard is open and allows scrolling.
  useEffect(() => {
    const header = headerRef.current;
    if (!header) return;
    const preventScroll = e => {
      e.preventDefault();
    };
    header.addEventListener("touchmove", preventScroll, { passive: false });

    return () => {
      header.removeEventListener("touchmove", preventScroll, {
        passive: false,
      });
    };
  }, []);

  // In case the mobile panel does get scrolled, force the window back to top.
  // This is another iOS workaround.
  useEffect(() => {
    if (
      !xs ||
      !window.visualViewport ||
      !window.visualViewport.addEventListener
    ) {
      return;
    }
    const preventScroll = () => {
      setTimeout(() => window.scrollTo(0, 0));
    };
    window.visualViewport.addEventListener("resize", preventScroll);
    window.visualViewport.addEventListener("scroll", preventScroll);
    return () => {
      window.visualViewport.removeEventListener("resize", preventScroll);
      window.visualViewport.removeEventListener("scroll", preventScroll);
    };
  }, [xs]);

  return (
    <Wrapper>
      <PanelHeader ref={headerRef}>
        {onBack && (
          <ButtonWrapper>
            <BackButton
              width="36px"
              height="36px"
              onClick={onBack}
              fill="black"
            />
          </ButtonWrapper>
        )}
        <HeaderContent center={!!onBack}>{header()}</HeaderContent>
        <ButtonWrapper>
          <CloseButton
            width="14px"
            height="14px"
            close={onClose}
            fill="black"
            id="panelCloseButton"
          />
        </ButtonWrapper>
      </PanelHeader>
      <PanelBody>{children}</PanelBody>
    </Wrapper>
  );
}
