import React from "react";
import styled from "styled-components";
import { swapCopyVariables } from "@sussex/react-kit/utils";
import useCopy from "../../hooks/useCopy";
import StartButton from "./StartButton";
import ChatButton from "./ChatButton";
import useParticipantInfo from "../../hooks/useParticipantInfo";
import { WaitingRoomChat } from "../Chat";
import PersonCircle from "../PersonCircle";
import { useAnalyticsContext } from "../../providers/AnalyticsProvider";

const Wrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const ClientName = styled.div`
  flex: 1;
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-size: 14px;
  line-height: 1.5;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 8px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export default function ClientItem({
  participant,
  onStart,
  startDisabled,
  loading,
  chatDisabled,
  index,
}) {
  const [genericUserText] = useCopy(["clientlist.genericUser"]);
  const {
    photoUrl,
    displayName,
    status: participantInfoStatus,
  } = useParticipantInfo(participant.participantId);
  const { analyticsEvent } = useAnalyticsContext();

  let name = participant.displayName ? participant.displayName : displayName;

  if (participantInfoStatus === "error") {
    name = swapCopyVariables(genericUserText, { NUMBER: index + 1 });
  }

  const startSession = () => {
    analyticsEvent({
      eventName: "user_action",
      actionType: "click",
      actionName: "start-session",
    });

    onStart();
  };

  return (
    <>
      <Wrapper>
        <PersonCircle imageURL={photoUrl} name={name} variant="large" />
        <ClientName>{name}</ClientName>
        <ButtonsWrapper>
          <ChatButton
            participant={participant}
            channel={participant.participantId}
            disabled={chatDisabled}
          />
          <StartButton
            participantId={participant.participantId}
            onClick={startSession}
            disabled={startDisabled}
            loading={loading}
          />
        </ButtonsWrapper>
      </Wrapper>
      <WaitingRoomChat
        isTherapist={true}
        participant={participant}
        channel={participant.participantId}
        onAdd={startSession}
        position="right"
        overlap={true}
        addDisabled={startDisabled}
        insets={{
          mobile: 81,
          tablet: 113,
          desktop: 113,
        }}
      />
    </>
  );
}
