import React from "react";
import useVideoContext from "../../hooks/useVideoContext";
import styled from "styled-components";
import Participant from "../Participant";
import ParticipantInfo from "../ParticipantInfo";
import useMainSpeaker from "../../hooks/useMainSpeaker";

const Wrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 169px;
  padding: 32px;
  overflow: hidden;
  box-shadow: none;

  box-sizing: border-box;
  & video {
    object-fit: cover;
    height: 100%;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    overflow-x: hidden;
    overflow-y: auto;
    justify-content: flex-start;
    flex-direction: column;
    height: 100%;

    & video {
      object-fit: contain;
      height: 100%;
    }
  }
`;

const ParticipantWrap = styled.div`
  border-radius: 16px;
  overflow: hidden;
  position: absolute;
  width: 75px
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const AspectRatio = styled.div`
  width: 80px;
  height: 105px;
  position: relative;
  overflow: hidden;
  margin-left: 8px;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 223px;
    height: 128px;
    margin-left: 0;
    margin-bottom: 8px;
    position: relative;
    flex-grow: 0;
  }
`;

const SecondaryParticipants = ({ isTherapist, visible }) => {
  const mainParticipant = useMainSpeaker();
  const {
    room: { localParticipant },
    participants: allParticipants,
    screenShareParticipant,
    selectedParticipant,
  } = useVideoContext();

  const participants = [...allParticipants, localParticipant].filter(p => {
    // If there is a screen share participant, but not presenter, display all participants
    if (screenShareParticipant && screenShareParticipant !== localParticipant)
      return true;
    // If there's no pinned participant, display local, unless local is only participant
    if (p === localParticipant && p !== mainParticipant) return true;
    // If there's a pinned participant, figure out the non-pinned participants
    return selectedParticipant && p !== mainParticipant;
  });

  if (participants.length === 0) {
    return null;
  }

  if (!visible) {
    return (
      <>
        {participants.map(p => (
          <Participant participant={p} disableVideo={true} key={p.identity} />
        ))}
      </>
    );
  }

  return (
    <Wrapper>
      {participants.map(p => (
        <AspectRatio key={p.identity}>
          <ParticipantWrap>
            <Participant
              participant={p}
              showAdminControls={isTherapist && p !== localParticipant}
            >
              <ParticipantInfo participant={p} thumbnail />
            </Participant>
          </ParticipantWrap>
        </AspectRatio>
      ))}
    </Wrapper>
  );
};

export default SecondaryParticipants;
